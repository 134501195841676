import React, { useContext } from 'react';
import { routeUtils } from 'tds-common-fe/src/lib/index';
import EmptyState from '../../../Shared/EmptyState';
import MailIcon from '../../../../images/mailIcon.svg';
import MailIconDark from '../../../../images/mailIcon_dark.svg';
import { usePostPageHeight } from '../PurchaseForm/usePostPageHeight';
import { ThemeContext } from '../../../Routes/ThemeProvider';
import { ProductCode, ProductCodeName } from '../../../../types/license';
import { useFormatMessage } from '../../../../localization/useFormatMessage';

const ContactSalesDone: React.FunctionComponent = () => {
    const { containerRef } = usePostPageHeight({ minHeight: 300 });
    const { theme } = useContext(ThemeContext);
    const iconSrc = theme === 'dark' ? MailIconDark : MailIcon;

    const { formatMessage } = useFormatMessage();

    const { requestedFreeTrial = 'false', product } = routeUtils.parseQuery<{
        requestedFreeTrial: boolean;
        product: ProductCode;
    }>(location.search);

    const description =
        requestedFreeTrial === 'true' && product
            ? formatMessage(
                  { id: 'ContactDone.RequestFreeTrialMessage' },
                  { product: ProductCodeName[product as ProductCode] }
              )
            : formatMessage({ id: 'ContactDone.Message' });

    return (
        <div ref={containerRef}>
            <EmptyState
                Icon={<img src={iconSrc} height="119" alt="mail" />}
                title="ContactDone.Title"
                description={description}
                style={{ padding: '60px 0' }}
            />
        </div>
    );
};

export default ContactSalesDone;
