import React, { useRef, useState } from 'react';
import { Form } from 'antd';
import { FormItem, NonOptionalKeys, routeUtils, useFormCheckErrors } from 'tds-common-fe';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppWrapper from '../../AppWrapper';
import styles from './ContactSales.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import ContactFields from '../Form/ContactFields';
import { FieldsType as ContactFieldsType } from '../Form/contactFieldsRules';
import { APP_ROUTES, getRouteWithUserID } from '../../../Routes/urls';
import { usePostPageHeight } from '../PurchaseForm/usePostPageHeight';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import * as licenseService from '../../../../api/licenseService';
import { getFormattedPhoneNumber } from '../../../../utils/phoneUtils';
import { RootState } from '../../../../reducers';
import { ProductCode, ProductCodeName } from '../../../../types/license';
import useRecaptcha from '../../../../hooks/useRecaptcha';
import { getCaptchaToken } from '../../../../utils/recaptchaUtils';
import RecaptchaTNC from '../../../Shared/RecaptchaTNC';

interface FieldsType extends ContactFieldsType {
    message: string;
}

const requiredFields: NonOptionalKeys<FieldsType>[] = ['countryCode', 'phone'];

class Item extends FormItem<FieldsType> {}

const RequestFreeTrial: React.FunctionComponent = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const { userID } = useParams<{ userID: string }>();
    const { product } = routeUtils.parseQuery<{
        product: string;
    }>(location.search);
    const productName = product ? ProductCodeName[product as ProductCode] : '';

    const inputs = useRef<HTMLInputElement[]>([]);
    const [useDefaultEmail, setUseDefaultEmail] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const { containerRef, scrollRef } = usePostPageHeight({ minHeight: 400 });

    const userProfile = useSelector((state: RootState) => {
        return state.profile.userProfile;
    });

    const { email, firstName, lastName, companyName } = userProfile;

    useRecaptcha();

    const handleSubmit = async (values: { [Key in keyof FieldsType]: string }) => {
        const { phone, countryCode } = values;
        const contactNumber = getFormattedPhoneNumber(phone, countryCode);
        const token = await getCaptchaToken('contact_sales');
        setIsLoading(true);

        licenseService
            .requestSalesContact(
                {
                    countryCode,
                    contactNumber,
                    firstName,
                    lastName,
                    contactEmail: email,
                    companyName,
                    licenseTierFree: true,
                    captcha: token,
                },
                product as ProductCode
            )
            .then(() => {
                // Navigate to success page
                const path = routeUtils.makeQueryPath(getRouteWithUserID(APP_ROUTES.CONTACT_SALES_DONE, userID), {
                    product,
                    requestedFreeTrial: 'true',
                });
                history.push(path);
            })
            .catch(() => {});
    };

    const { checkErrors } = useFormCheckErrors(form, requiredFields);

    return (
        <AppWrapper className={styles.app_wrapper}>
            <div className={styles.container} ref={containerRef}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit as any}
                    className={styles.form}
                    requiredMark={false}
                >
                    <div className={styles.form_scrollable_area} ref={scrollRef}>
                        <div className={styles.form_section}>
                            <p>
                                <FormattedMessage
                                    id="Contact.Header.RequestFreeTrial"
                                    values={{ product: productName }}
                                />
                            </p>
                            <p>
                                <div>
                                    <FormattedMessage id="Contact.Header.RequestFreeTrialEmail" />
                                </div>
                                <div>
                                    <b>{email}</b>
                                </div>
                            </p>
                            <ContactFields
                                inputs={inputs}
                                form={form}
                                useDefaultEmail={useDefaultEmail}
                                onSetUseDefaultEmail={setUseDefaultEmail}
                                emailCheckboxMessage="Contact.Email.EagleID.SameEmail"
                                emailSettings={{
                                    label: 'Contact.Field.Email.Label',
                                    placeholder: 'Contact.Field.Email.Label',
                                    emptyErrorMessage: 'Contact.Field.Email.Error.Empty',
                                }}
                                shortForm
                            />
                            <div>
                                <RecaptchaTNC />
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.actions_area} fill_form_row`}>
                        <Item shouldUpdate style={{ margin: 0 }}>
                            {() => (
                                <div className={styles.button_wrapper_container}>
                                    <div className={styles.button_wrapper}>
                                        <AnalyticsButton
                                            className={styles.button_text}
                                            type="primary"
                                            htmlType="submit"
                                            disabled={checkErrors()}
                                            loading={isLoading}
                                        >
                                            <FormattedMessage id="Contact.Button.RequestFreeTrial" />
                                        </AnalyticsButton>
                                    </div>
                                </div>
                            )}
                        </Item>
                    </div>
                </Form>
            </div>
        </AppWrapper>
    );
};

export default RequestFreeTrial;
